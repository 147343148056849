import { Author } from "~/author";

interface AuthorBlock {
  author: Author;
}
const AuthorBlock = (props: AuthorBlock) => {
  const { author } = props;
  return (
    <div className="m-auto flex my-4">
      <img
        className="w-10 rounded-full mr-2"
        src={author.avatar}
        alt="avatar"
      />
      <p className="my-auto text-gray-600 text-sm font-title font-bold">
        {author.name}
      </p>
    </div>
  );
};

export default AuthorBlock;
