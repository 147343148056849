import { Link } from "react-router-dom";
import { Post } from "~/post";
import AuthorBlock from "./AuthorBlock";

interface TopPostProps {
  post: Post;
}

const TopPost = (props: TopPostProps) => {
  const { post } = props;
  return (
    <article className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-1/2 lg:mr-10">
        <img
          className="rounded-2xl h-full object-cover"
          src={post.coverImage}
          alt="cover image"
        />
      </div>
      <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:mx-6">
        <p className="text-3xl lg:text-5xl font-title font-bold text-gray-800">
          {post.title}
        </p>
        <p className="my-4 text-gray-700 font-title">{post.date}</p>
        <p className="text-lg lg:text-xl text-gray-500 my-8 font-body leading-relaxed">
          {post.description}
        </p>
        <div>
          <Link
            to={`/blog/${post.slug}`}
            className="font-bold text-blue-900 lg:text-lg"
          >
            Read Post &rarr;
          </Link>
        </div>
        <div className="my-8">
          <AuthorBlock
            author={{
              avatar: post.avatar,
              name: post.author,
            }}
          />
        </div>
      </div>
    </article>
  );
};

export default TopPost;
