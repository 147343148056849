import { Link } from "@remix-run/react";
import { Post } from "~/post";
import AuthorBlock from "./AuthorBlock";
import TopPost from "./TopPost";

interface PostIndexProps {
  posts: Post[];
}

const PostIndex = (props: PostIndexProps) => {
  const { posts } = props;
  return (
    <>
      <ul className="mt-10">
        {posts
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((post) => (
            <div className="my-20" key={post.title}>
              <TopPost post={post} />
            </div>
          ))}
      </ul>
    </>
  );
};

export default PostIndex;
